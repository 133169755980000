import { useRouteError } from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "~/components/card";
import { useEffectUnsafe } from "~/hooks/unsafe-hookst";
import { useLog } from "~/hooks/use-log";
import HeatPumpSadFace from "~/images/heat-pump-with-sad-face.svg?url";

const errorMessages: Record<number, Record<"title" | "message", string>> = {
	403: { title: "accessDenied", message: "youHaveNoAccess" },
	404: { title: "pageNotFound", message: "pageDoesntExist" },
};

export const ErrorScreen = () => {
	const error = useRouteError() as { status?: number };
	captureRemixErrorBoundaryError(error);

	const { t } = useTranslation();

	const log = useLog();

	useEffectUnsafe(() => {
		log(error as never, "error");
	}, []);

	const errorMessage =
		typeof error.status === "number" ? errorMessages[error.status] : undefined;

	return (
		<div className="h-full-ios-screen flex p-4">
			<Card className="mx-auto mb-auto w-full max-w-[500px] flex-col items-center gap-1 p-6">
				<img
					className="my-6"
					width={204 * 1.2}
					height={131 * 1.2}
					src={HeatPumpSadFace}
					alt={t("heatPumpWithSadFace")}
				/>
				<h1 className="m-0">{t(errorMessage?.title ?? "ooops")}</h1>
				<span className="mx-2 text-center sm:mx-8">
					{t(errorMessage?.message ?? "somethingWentWrong")}
				</span>
				{/*styles where made inline because MUI styles are not loaded on error boundaries*/}
				<a
					href="/"
					className="mt-8 rounded-sm bg-brand px-4 py-2 text-black no-underline hover:bg-gray2"
				>
					{t("backToHome")}
				</a>
			</Card>
		</div>
	);
};
